$yellow: #a7b59b;
$gray: #c9c9c9;
$skyblue: #73bbea;
$green: #01cb9b;
$blue: #57ccf7;
.c-gray {
  color: $gray;
}
.c-yellow {
  color: $yellow;
}
.c-skyblue {
  color: $skyblue;
}
.c-green {
  color: $green;
}
.c-blue {
  color: $blue;
}

.orange {
  color: #a7b59b;
}
.white {
  color: white;
}
.grey {
  color: #686767;
}
.lightGrey {
  color: #c9c9c9;
}
.c-black {
  color: #292641;
}
.red {
  color: #ff4444;
}
.green {
  color: #73bbea;
}
.c-lightBlack {
  color: #707070;
}

.theme-btn {
  padding: 16px 30px;
  color: #fff;
  border-radius: 15px;
  border: 0px;
  cursor: pointer;
  font-family: "PT Sans", sans-serif;
  position: relative;
  &.sm {
    padding: 6px 8px;
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    min-width: 72px;
    justify-content: center;
  }

  &.blue {
    background: linear-gradient(180deg, #58cdf8, #067ed3);
    &:hover {
      background: linear-gradient(0deg, #58cdf8, #067ed3);
    }
  }

  &.black {
    background: #292641 0% 0% no-repeat padding-box;
    &.outline {
      border: 1px solid #292641;
      background: transparent;
      color: #292641;
    }
  }
  .mat-progress-spinner {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #ffffff;
  }
}
