.m-0 {
    margin: 0 !important;
}
*,
* {
    box-sizing: border-box;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 5rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

.col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}
.cursor{
    cursor:pointer;
}
.col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    display: flex;
}

.col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}

.col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.form-group {
    margin-top: 20px;
    position: relative;
    width: 100%;
    .form-control {
        background: #ffffff33 0% 0% no-repeat padding-box;
        color: white;
        -webkit-backdrop-filter: blur(30px);
        backdrop-filter: blur(30px);
        border: 2px solid white;
        border-radius: 14px;
        outline: none;
        font-size: 18px;
        width: 100%;
        padding: 20px 34px 20px 34px;
        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #fff;
        }
        + .error {
            display: none;
        }
        &.ng-invalid.ng-touched {
            border-color: red;
            + .error {
                display: inline;
            }
        }
    }
    .icon {
        position: absolute;
        left: 0px;
        top: 2px;
    }
    .eye {
        position: absolute;
        bottom: 24px;
        right: 12px;
    }
}

//DISPLAY PROPERTY CSS//
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}
.d-flex {
    display: flex;
}
.d-none {
    display: none;
}
.d-inline {
    display: inline;
}
.clearfix {
    overflow: auto;
}
.align-center {
    display: flex;
    align-items: center;
}
.justify-center {
    display: flex;
    justify-content: center;
}
.justify-between {
    display: flex;
    justify-content: space-between;
}
.justify-end {
    display: flex;
    justify-content: flex-end;
}
.center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.mx-auto {
    margin-right: auto;
    margin-left: auto;
}
.w-100 {
    width: 100%;
}
.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.row {
    display: flex;
    flex-wrap: wrap;
}
.p-relative {
    position: relative;
}
img {
    max-width: 100%;
}
.opacity-5 {
    opacity: 0.5;
}
.border-right {
    border-right: 1px solid #aeb3c0;
}
.f-w-600 {
    font-weight: 600;
}
.f-w-700 {
    font-weight: 700;
}
.f-w-400 {
    font-weight: 400;
}
.text-center {
    text-align: center;
}

.fs-10 {
    font-size: 10px;
}
.fs-12 {
    font-size: 12px;
}
.fs-12 {
    font-size: 12px;
}
.fs-13 {
    font-size: 13px;
}
.fs-14 {
    font-size: 14px;
}
.fs-15 {
    font-size: 15px;
}
.fs-16 {
    font-size: 16px;
}
.fs-17 {
    font-size: 17px;
}
.fs-18 {
    font-size: 18px;
}
.fs-19 {
    font-size: 19px;
}
.fs-20 {
    font-size: 20px;
}
.fs-21 {
    font-size: 21px;
}
.fs-22 {
    font-size: 22px;
}
.fs-24 {
    font-size: 24px;
}
.fs-25 {
    font-size: 25px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

.flex1 {
    flex: 1;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.align-items-baseline {
    align-items: baseline;
}
.pointer {
    cursor: pointer;
}
.break-all{
    word-break:break-all;
}
.d-none{
    display: none !important;;
}

.justify-between{
    justify-content: space-between;
}