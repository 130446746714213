@import "./layout.scss";
@import "./theme.scss";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ello-admin-primary: mat-palette($mat-indigo);
$ello-admin-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ello-admin-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ello-admin-theme: mat-light-theme(
  (
    color: (
      primary: $ello-admin-primary,
      accent: $ello-admin-accent,
      warn: $ello-admin-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($ello-admin-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "PT Sans", sans-serif;
  font-size: 16px;
  color: #292641;
}
* {
  font-family: "PT Sans", sans-serif;
}

input::placeholder {
  font-family: "PT Sans", sans-serif !important;
  color: grey !important;
}
textarea::placeholder {
  font-family: "PT Sans", sans-serif !important;
  color: grey !important;
}
textarea {
  resize: none;
}

*::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #fff;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
}
*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fff;
}

.form-container {
  background-image: url("../src/assets/images/bg-allstock.png");
  background-size: cover;
  display: flex;
  height: 100%;
  align-items: center;
  color: #fff;
  .form-box {
    background-color: #000;
    background-image: url("../src/assets/images/form-bg.png");
    background-size: cover;
    padding: 30px 80px;
    border-radius: 70px;
    max-width: 490px;
    min-height: 465px;
    margin: auto;
  }
}
.auth-input {
  margin-bottom: 22px;
  label {
    color: #c9c9c9;
    font-size: 16px;
    padding: 0px 15px;
  }
  div {
    position: relative;
    margin-top: 10px;
    input,
    input:-webkit-autofill {
      background: #ffffff40 0% 0% no-repeat padding-box !important;
      border: 2px solid #ffffff66;
      border-radius: 15px;
      opacity: 1;
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);
      padding: 15px 10px;
      width: 100%;
      padding-left: 40px;
      color: #fff;
      font-size: 16px;
      &::placeholder {
        color: white;
        font-size: 16px;
      }
      &.password-field {
        padding-right: 46px !important;
      }
      &:focus {
        outline: none;
      }
    }
    .icon {
      position: absolute;
      top: 0;
      left: 15px;
      bottom: 0;
      margin: auto;
    }
    .password {
      right: 15px;
      left: auto;
      cursor: pointer;
      padding: 4px;
    }
  }
}
.back {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
  margin: auto;
  cursor: pointer;
}

.error {
  position: absolute !important;
  bottom: -20px;
  color: #be2323;
  font-size: 15px;
  left: 15px;
  display: none;
}
.ng-invalid.ng-touched + .error {
  display: inline;
  font-weight: 600;
}

.wrapper {
  display: flex;
  margin-top: 10px;
  .otp-input {
    height: 67px !important;
    border: 2px solid #dedede !important;
    border-radius: 15px !important;
    width: 62px !important;
    background-color: transparent !important;
    font-size: 26px !important;
    margin: auto;
    color: #fff;
    &:focus {
      outline: none;
    }
  }
}

.lightloader {
  .loading.mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #008cff;
  }
}

// font-size
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-17 {
  font-size: 17px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-21 {
  font-size: 21px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}

// font-weight
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}

// profile-outer
.inner-form-dropdown {
  .mat-select-arrow {
    border: none !important;
  }
}
.profile-outer {
  background-image: url("/assets/images/profilebg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  height: 100vh;
  border-radius: 0px 38px 38px 0px;
  padding: 2rem 1.5rem 0.5rem 1.5rem;
  .back {
    position: absolute;
    right: -50px;
    top: -9px;
  }
}

.border-img {
  border-radius: 30px;
  border: 1px solid #ffffff;
  // width: 91px;
  // height: 91px;
  width: 80px;
  height: 80px;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0) !important;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 15px);
  padding: 0px 24px;
}

.page-content {
  flex: 1;
  overflow-y: auto;

  .table {
    flex: 1;
    overflow: auto;
    width: 100%;
  }
}

.list-table {
  width: 100%;

  th,
  td {
    text-align: left;
    padding: 10px;
    font-size: 14px;
  }

  th {
    color: rgb(115, 115, 115, 0.8);
    font-weight: 600;
    font-size: 14px;
    padding: 10px 10px 0px 10px;
  }
}

.tabs {
  background: #d4d3d9 0% 0% no-repeat padding-box;
  border-radius: 18px;
  padding: 4px 0px;
  display: inline-flex;
  .btn {
    padding: 8px 15px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 600;
    margin: 0px 4px;
    color: #292641;
    &.active {
      background-color: #ffffff;
    }
  }
}

.box {
  box-shadow: 0px 5px 20px #0000000d;
  border-radius: 10px;
  padding: 20px;
}

.dropdown {
  .mat-menu-content {
    padding: 8px 8px !important;
  }
  .mat-menu-item {
    height: 33px;
    line-height: 0px;
    border-bottom: 1px solid #f1f1f1;
    padding: 0px 4px !important;
    &:last-child {
      border: 0px !important;
    }
  }
}

.search {
  position: relative;
  input {
    width: 326px;
    padding: 6px;
    padding-right: 27px !important;
    background-color: #f0f0f0;
    color: #292641;
    border-radius: 8px;
    border: none;
    outline: none;
    font-size: 12px;
    &::placeholder {
      color: #292641;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .search-icon {
    position: absolute;
    right: 8px;
    top: 6px;
  }
}

.filter-dropdown {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  height: 28px;
  border-radius: 8px;
  padding: 1px 6px 0px 10px;
  margin: 0px 10px;
  font-size: 12px;
  width: 156px;
  color: #292641;
  img {
    margin-right: 10px;
  }
}

.box {
  box-shadow: 0px 5px 20px #0000000d;
  border-radius: 10px;
  background-color: white;
  padding: 15px;
}

.exercise-img {
  width: 98px;
  height: 67px;
  border-radius: 10px;
}

.darkGrey {
  color: #707070;
}

.mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #a7b59b;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #a7b59b;
}

.calander-filter {
  border-bottom: 1px solid #dedede;
  display: inline-flex;
  align-items: center;
  span {
    margin-right: 20px;
    position: relative;
    &.active {
      font-weight: 600;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        height: 2px;
        width: 100%;
        background-color: #000;
        bottom: -1px;
        z-index: 1;
      }
    }
  }
}

.inner-form-field {
  background-color: #f0f0f0;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 12px;
  width: 100%;
  margin-top: 1.5rem;
  color: #292641;
  font-size: 14px;
  &::placeholder {
    color: black;
    font-size: 14px;
    font-weight: 500;
  }
}

.inner-form-dropdown {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  height: 42px;
  border-radius: 8px;
  margin: 0px 0px;
  font-size: 12px;
  padding: 0px 12px;
  width: 100%;
  color: #292641;
}

.mat-select-placeholder {
  color: grey !important;
  font-size: 14px;
  font-weight: 500;
}

hr {
  border-width: 0px;
  border-bottom: 1px solid #c9c9c9;
}

.mbb-1 {
  margin-bottom: 0.12rem;
}

form {
  .error-icon {
    width: 20px;
    position: absolute;
    right: 6px;
    bottom: 12px;
    display: none;
    background-color: #f0f0f0;
    &.textarea-field {
      top: 2px !important;
      right: -6px !important;
    }
  }
  .ng-invalid.ng-touched {
    + .error-icon {
      display: inline;
    }
  }
  .excercise-item {
    &:first-child {
      hr {
        display: none;
      }
    }
  }
}

button {
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.addForm {
  width: 100%;
  input {
    background-color: #f0f0f0;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 12px;
    width: 100%;
    margin-top: 1.5rem;
    color: #292641;
    font-size: 14px;
    &::placeholder {
      color: #292641;
      font-size: 14px;
    }
  }
}

.mat-dialog-container:has(.add-exercise) {
  max-height: 80vh;
  overflow: auto;
}

.mat-dialog-container {
  max-height: 80vh !important;
}

// add scss
.upload {
  height: 147px;
  mix-blend-mode: luminosity;
  border: 2px dashed #dedede;
  border-radius: 15px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

::placeholder {
  color: black;
  font-size: 14px;
  font-weight: 500;
}

.textarea-control {
  background-color: #f0f0f0;
  border-radius: 5px;
  border: none;
  outline: none;
  width: 100%;
  color: #292641;
  font-size: 14px;
  height: 179px;
}

.textarea-outer {
  background-color: #f0f0f0;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 12px;
  width: 100%;
  color: #292641;
  font-size: 14px;
}

::ng-deep.mat-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 0;
  margin-top: 1.5rem;
  width: 549px !important;
  background-color: #f0f0f0 !important;
  border-radius: 5px;
}

::ng-deep.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px !important;
}

.img-add {
  width: 276px;
  height: 147px;
  border-radius: 15px;
  object-fit: cover;
}

.category-dropdown {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  height: 42px;
  border-radius: 8px;
  margin: 0px 0px;
  font-size: 12px;
  padding: 0px 12px;
  width: 100%;
  color: #292641;
}

::ng-deep.mat-select-placeholder {
  color: black !important;
  font-size: 14px;
  font-weight: 500;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.ng-invalid.ng-touched + .error-icon {
  display: inline;
}

.radio-btn {
  .mat-radio-outer-circle {
    width: 15px;
    height: 15px;
    top: 3px;
    left: 2px;
  }
  .mat-radio-inner-circle {
    width: 15px;
    height: 15px;
    top: 3px;
    left: 2px;
  }
  .mat-radio-label-content {
    padding-left: 11px;
  }
}

.text-underline {
  text-decoration-line: underline;
}

.viewBg {
  .mat-dialog-container {
    padding: 0px !important;
    border-radius: 10px !important;
    min-height: 0px;
  }
}

.user-active-inactive {
  height: 7px;
  width: 7px;
  border: none;
  border-radius: 50%;
  margin-right: 7px;
  &.active {
    background-color: rgb(32 235 93);
  }
  &.inactive {
    background-color: rgb(174 179 192);
  }
  &.blocked {
    background-color: rgb(247, 59, 59);
  }
}
.mat-select-panel-wrap {
  margin-top: 39px;
}

.p-relative {
  position: relative;
}
.dialogBox .mat-dialog-container {
  border-radius: 10px;
}
.border-bottom {
  width: 100%;
  border-bottom: 1px solid whitesmoke;
  padding-bottom: 10px;
}
.cdk-overlay-pane:has(.add-recipe) {
  min-height: unset !important;
  height: 100vh;
  overflow: auto;
}

.header-for-small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 24px;
  background-color: #000;
  margin: 0 -24px;
  display: none;
}
.header-for-small .menu-opener-btn{
  background-color:unset;
  background:unset;
  border:0;
  cursor: pointer;
}

.header-for-small .menu-opener-btn img.hamburger{
  width: 20px;
}
.header-for-small .menu-opener-btn img.cross{
  width: 15px;
}
.cross {
  display: none;
}

body.menu-open .hamburger {
display: none;
}
body.menu-open .cross{
display: block;
}

@media screen and (max-width:768px) {
  .form-container .form-box {
    border-radius:25px;
    margin:15px;
    padding:20px 30px;
    max-width: unset;
    // width: 100%;
  }
  body .sidenav-outer {
    width: 100% !important;
  }

 
  body.menu-open .menu-opener-btn {
    z-index: 9999999;
  }
}

.sidebar-link-wrapper {
  padding-bottom: 50px !important;
}

@media screen and (max-width:992px) {
  .header-for-small {
    display: flex;
  }


  .sidenav-outer .sidebar-list ul {
padding-bottom: 100px !important;
  }

  body .sidenav-outer {
    position: fixed !important;
    right:0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 9999;
    transform: translateX(-100%);
    height: 100vh;
  }
  body.menu-open .sidenav-outer {
    transform: translateX(0) !important;
  }
  .toggle.pointer {
    display: none;
  }
}
